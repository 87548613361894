import React from "react"
import { Link } from "gatsby"

import { Nav, NavItem, NavLink } from 'reactstrap';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Teamimage from "./../images/0f6dee8c-c190-4c38-bfb5-06acb0fe88d8.jpg"

const SecondPage = () => (
  <Layout>
    <SEO title="About us" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Teamimage + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">About us</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <Nav className="py-4">
        <NavItem>
          <NavLink href="#">Background</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#goals">Goals</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Mission and Vision</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our values</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Partners</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Priorities</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our approach</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Success stories</NavLink>
        </NavItem>
      </Nav>
    </section>
    <section className="section section-lg pt-6 bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5 mb-md-4">
          <div className="col-12 col-md-8 text-center">
            <h4 className="h1 font-weight-bolder mb-4">
              <span className="text-primary">R</span>WANDA{" "}
              <span className="text-secondary">H</span>EALTH{" "}
              <span className="text-warning">I</span>NITIATIVE FOR{" "}
              <span className="text-info">Y</span>OUTH AND{" "}
              <span className="text-danger">W</span>OMEN (RHIYW)
            </h4>
            <p className="lead">
              Rwanda Health Initiative for youth and women is a non-governmental, non-profit organization focusing specifically on improving the lives of adolescents, young people and women.
            </p>
          </div>
        </div>
        <div
          id="goals"
          className="row row-grid align-items-center justify-content-between mb-2 mt-2 mb-md-2"
        >
          <div className="col-12 col-md-12">
            <h2 className="font-weight-bolder mb-4">1. Background</h2>
            <p className="lead">
              RHIYW is a Rwandan non-governmental organization born out of the passion and involvement of youth SRH champions, clinicians, health care advocates and public health experts. RHIYW implements diverse public health interventions in reproductive health (RH) and maternal health (MH). RHIYW utilize effective, sustainable approaches and strategies to accelerate the reduction of the morbidity and mortality associated to untended pregnancy, unsafe abortion, sexual violence, sexual transmitted disease. RHIYW collaborate with public health institutions and organization working in the domain of Healthcare, health promotion, Human Rights and advancing social justice. In addition to the local team RHIYW have an advisory committee made up of global experts in the fields of sexual reproductive health, maternal health, public health and advocacy.
            </p>
          </div>
        </div>

        <div
          id="goals"
          className="row row-grid align-items-center mb-2 mt-2 mb-md-2"
        >
          <div className="col-12 col-md-12">
            <h2 className="font-weight-bolder mb-4">2. Goals</h2>
            <p className="lead">
              To ensure that adolescents, young people and women live healthy lives; by implementing project aiming at prevention of unintended pregnancies; Gender based & sexual violence, Sexual transmitted disease, and maternal disabilities and maternal deaths. And empowering adolescents, youth and women to make informed choices about their own bodies.
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-12">
            <h2 className="font-weight-bolder mb-4">3. Mission & Vision</h2>
            <p className="lead">
              RHIYW’s mission is to enable adolescents, youth and women to lead interventions in sexual reproductive health (SRH) and maternal health.
            </p>
            <p className="py-2"></p>
            <h2 className="font-weight-bolder mb-4">4. Our Values</h2>
            <p className="lead">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>Gender equality </li>
                    <li>Equity</li>
                    <li>Accountability</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Partnership</li>
                    <li>Transparency</li>
                    <li>Sustainability</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Integrity</li>
                    <li>Creativity/Innovation</li>
                    <li>Excellence</li>
                  </ul>
                </div>
              </div>
            </p>
          </div>
        </div>


      </div>
    </section>

    <section className="section section-lg pt-6">
      <div className="container flex flex-col gap-8">
        <div className="row row-grid align-items-center">
          <div className="col-12">
            <h2 className="font-weight-bolder mb-4">5. Partners</h2>
            <p className="lead">
              <div className="row">
                <div className="col">
                  <ul className="list-disc">
                    <li>Rwanda Ministry of Health (MoH)</li>
                    <li>
                      Rwanda Biomedical Center (RBC)
                    </li>
                    <li>Ministry of Youth and Culture</li>
                    <li>District Health units</li>
                    <li>District Joint Action Development Forum (JADF) forum</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc">
                    <li>Bixby center, University of California Berkeley</li>
                    <li>The David and Lucile and Packard Foundation</li>
                    <li>Rwanda Society of Obstetricians and Gynecologists (RSOG)</li>
                    <li>Royal College of Obstetrician and Gynecology (RCOG) IPAs Africa alliance</li>
                    <li>KASHA</li>
                    <li>Preston Werner Ventures</li>
                    <li>Venture Strategies for Health and Development(VSHD)</li>
                    <li>Hesperian Health Guides</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc">
                    <li>
                      Great Lakes Initiatives for Human rights and Development
                      (GLIHD )
                    </li>
                    <li>
                      Health Development Initiative (HDI)
                    </li>
                    <li>
                      Nurses and Midwives professional organizations
                    </li>
                  </ul>
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className="row gap-8">
          <div className="col-12 ">
            <h2 className="font-weight-bolder mb-4">6. Our Priorities</h2>
            <p className="lead">
              <ol className="list-decimal">
                <li>To conduct operations research, and monitoring & evaluation programs to guide policy and practices and maternal health.</li>
                <li>Implement strategies of using telemedicine to increase access to sexual reproductive health and maternal health services.</li>
                <li>Support to develop approaches to the management of health through digital health applications.</li>
                <li>Reinforce Implementation of strategies and programs that aim to improve the health of women during pregnancy and child birth.</li>
                <li>To implement innovative interventions for effective provision of youth friendly sexual reproductive health services in public primary health facilities localized in rural areas.</li>
                <li>To support public youth centers to deliver trusted, confidential, comprehensive sexual reproductive health services.</li>
                <li>Empowering nurses and midwives working in rural public health facilities in sexual and reproductive and maternal health services provision.</li>
                <li>Strengthen the provision of comprehensive sexuality education (CSE) to adolescents and young people.</li>
                <li>Provide technical support to the Ministry of Health to implement strategies for reducing maternal mortality rate associated to unsafe abortion, sexual violence and sexual transmitted disease.</li>
                <li>Strengthening Isange One Stop Centers (GBV Centers)/public health facilities in their unique role to help victims for gender-based violence.</li>
                <li>To promote family planning services provision with emphasis on long term contraceptive methods.</li>
                <li>To ensure availability of quality Sexual  and maternal health commodities and medical equipment in public health facilities.</li>
                <li>Increase coverage for sexual reproductive health services in rural areas served by faith-based health facilities.</li>
                <li>Improving access to sexual reproductive health services for high risk groups of young people including teen mothers and sex workers.</li>
                <li>To strengthen the leadership capacity of young people in SRH programs, especially those for adolescent girls and young women.</li>
                <li>To create opportunities/platforms for discussions, sharing ideas and best practices in SRH services and provisions.</li>
                <li>To build the capacity of community health workers in the provision of reproductive health education and services.</li>
                <li>To implement strategies for fighting against gender discrimination and sexual violence.</li>
                <li>Strengthen gender equality and health equity approaches and accessibility for people with disabilities</li>
                <li>To implement socio-economic strategies for vulnerable group of adolescent girls and young women.</li>

              </ol>
            </p>
          </div>
          <div className="col-12 ">
            <h2 className="font-weight-bolder mb-4">7. Our Approach</h2>
            <p className="lead">
              <ol className="list-decimal">
                <li>Use digital health initiative.</li>
                <li>Involve and promote youth participation.</li>
                <li>Empowering adolescent’s girls and women.</li>
                <li>Establish strong partnerships and support government institutions in project implementation</li>
                <li>Implement peer education activities.</li>
                <li>Provide integrated, youth-friendly services that are in accordance with national policies.</li>
                <li>using a client-centered approach.</li>
                <li>Prioritize confidentiality and privacy.</li>
                <li>Mobile outreach in rural areas.</li>
                <li>Collaborate with local and international NGOs.</li>
                <li>Community involvement.</li>
                <li>Improve access to services for vulnerable groups.</li>
                <li>Inclusion</li>
                <li>Promote the use modern information and communication technologies (ICTS) in provision of sexual reproductive health services and education.</li>
                <li>Collaborate with National and International public health research institutions.</li>
                <li>Empowering in service health care workers; nurses, midwives, medical doctors and others.</li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
